import React, { createContext, useState } from 'react';

import {
  getRealTimeProviders,
  getLocationOfProvider,
  getRealTimeLogs,
  getRealTimeJobs,
  getRealTimeServices
} from '../controllers/snowm_firebase';

export const ServicePContext = createContext();

export function ServicePProvider({ children }) {
  const [providers, setProviders] = useState(null);
  const [locations, setLocations] = useState({});
  const [providersLoading, setProvidersLoading] = useState(null);
  const [logs, setLogs] = useState([]);
  const [jobs, setJobs] = useState(null);
  const [services, setServices] = useState(null);

  function _updateProviderLocation(uid, value) {
    locations[uid] = value;
    setLocations({ ...locations });
  }
  function _updateProviders(pros) {
    setProvidersLoading(false);
    Object.values(pros).forEach(p => {
      if (!locations[p.uid]) {
        getLocationOfProvider(p.uid, _updateProviderLocation);
      }
    });
    setProviders({ ...pros });
  }
  function fetchProviders() {
    setProvidersLoading(true);
    getRealTimeProviders(_updateProviders);
  }
  function _updateLogs(ls) {
    setLogs(ls);
  }
  function getLogs() {
    getRealTimeLogs(_updateLogs);
  }

  function _updateJobs(jobData) {
    setJobs(jobData);
  }

  function fetchJobs() {
    getRealTimeJobs(_updateJobs);
  }

  function _updateServices(serviceData) {
    setServices(serviceData);
  }

  function fetchServices() {
    getRealTimeServices(_updateServices);
  }
  return (
    <ServicePContext.Provider
      value={{
        providers,
        providersLoading,
        fetchProviders,
        locations,
        logs,
        getLogs,
        jobs,
        fetchJobs,
        fetchServices,
        services
      }}
    >
      {children}
    </ServicePContext.Provider>
  );
}
