import styled from 'styled-components';
import { TextField, Typography } from '@material-ui/core';

const StyledTextField = styled(TextField)`
  && {
    .MuiInputBase-input::placeholder {
      font-size: 20px;
    }
  }
`;

export const Text = styled(Typography).attrs({ component: 'span' })`
  && {
    padding-left: 10px;
  }
`;

export const BoldTitle = styled(Typography).attrs({ component: 'span' })`
  && {
    font-weight: bold;
  }
`;

export default StyledTextField;
