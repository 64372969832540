/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Private/index.js
 *
 *  Description: Entry point of private components.
 *
 *  Author: Nabin Kharal (nabin@brainants.com) and Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';
import BookIcon from 'mdi-react/BookIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import HomeIcon from 'mdi-react/HomeOutlineIcon';
import { withTheme } from '@material-ui/core/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';

import Home from './Home';
import Jobs from './Jobs';
import Routes from './routes';
import ServicePoints from './markers';
import Properties from './properties';
import ServiceLogs from './serviceLogs';
import WorkOrders from './serviceRequests';
import AppBar from '../Generics/snowm_appbar';
import Drawer from '../Generics/snowm_drawer';
import Loader from '../Generics/snowm_loader';
import UserProfile from './Profile/UserProfile';
import { ServiceTypesContext } from '../../contexts/service_types';
import { LocalizationContext } from '../../contexts/localization_context';

// const { HOME, JOBS, CREW, SERVICES, MARKERS, ROUTES } = en.sidemenu;

/*
 ****************************************************************************
 * @brief  (getRoutes)      renders the list of routes
 * @param{type : Array,Function,Object } (routesToRender,setActive,theme)
 *
 * @return  returns a jsx element containing list of all routesToRender
 *
 ****************************************************************************
 */
function getRoutes(routesToRender, setActive, theme) {
  return (
    <>
      {routesToRender.map((route) => {
        return (
          <Switch key={route.name}>
            <Route
              path={route.route}
              component={(p) => {
                useEffect(() => {
                  setActive(route.route);
                }, []);
                const Component = route.component;
                return <Component {...p} theme={theme} />;
              }}
              exact={route.exact}
            />
          </Switch>
        );
      })}
    </>
  );
}

const drawerWidth = 240;

// Functional Component
const Dashboard = (props) => {
  const { strings } = useContext(LocalizationContext);
  const { HOME, JOBS, MARKERS, USER_PROFILE, ROUTES } = strings?.sidemenu;
  const [active, setActive] = useState('');
  const { theme } = props;

  // return content of the private component (with appropriate routes)

  const data = useContext(ServiceTypesContext);

  const { subscribedServices } = data;

  const routes = [
    {
      route: '/home',
      component: Home,
      name: HOME,
      icon: <HomeIcon />,
      exact: true,
    },
    {
      route: '/home/jobs',
      component: Jobs,
      name: JOBS,
      icon: <PlaylistCheckIcon />,
    },
    {
      route: '/home/routes',
      component: Routes,
      name: ROUTES,
      icon: <RoutesIcon />,
      hide: !subscribedServices?.isRouteAvailable,
    },
    {
      route: '/home/markers',
      component: ServicePoints,
      name: MARKERS,
      icon: <HomeMapMarkerIcon />,
      hide: !subscribedServices?.markers,
    },
    {
      route: '/home/serviceRequests',
      component: WorkOrders,
      name: 'Service Requests',
      icon: <AccountHardHatIcon />,
    },
    {
      route: '/home/properties',
      component: Properties,
      name: 'Properties',
      hide: !subscribedServices?.properties,
      icon: <HomeCityOutlineIcon />,
    },
    {
      route: '/home/profile',
      component: UserProfile,
      name: USER_PROFILE,
      icon: <AccountCircleIcon />,
      menu: true,
    },
    {
      route: '/home/servicelogs',
      name: 'Service Logs',
      component: ServiceLogs,
      icon: <BookIcon />,
    },
  ];

  useEffect(() => {
    data.getDetails();
  }, []);

  if (!subscribedServices) return <Loader />;

  return (
    <Body>
      <Row>
        <AppBar routes={routes} {...props} />
      </Row>
      <Content>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
          }}
        >
          <Drawer
            active={active}
            width={drawerWidth}
            routes={routes}
            {...props}
          />
          <Main theme={theme}>
            <StyledContent>
              <Switch>
                {getRoutes(routes, setActive, theme)}
                <Redirect to="/home" exact />
              </Switch>
            </StyledContent>
          </Main>
        </div>
      </Content>
    </Body>
  );
};

export default withTheme(Dashboard);

const Body = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
`;

const Content = styled.div`
  flex: 1;
`;

const Main = styled.div`
  width: 100%;
  overflow: auto;
  background: #f2ecec;
`;

const StyledContent = styled.div`
  height: 100%;
`;
