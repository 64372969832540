/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_login.jsx
 *
 *  Description: Login component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useState, useContext } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import { withTheme } from '@material-ui/core/styles';
import { CircularProgress, TextField, Link } from '@material-ui/core';

import Span from '../Common/Span';
import color from '../../utils/color';
import ADialog from '../Common/styled';
import ResetPassword from './ResetPassword';
import { Row } from '../../styles/snowm_styled';
import { ValidateEmail } from '../../helpers/snowm_validators';
import UnAuthenticated, { StyledOutlineButton } from './UnAuthenticated';
import { loginWithEmailPassword } from '../../controllers/snowm_firebase';
import { LocalizationContext } from '../../contexts/localization_context';

// functional component
const Login = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { strings } = useContext(LocalizationContext);
  // const {
  //   inputLabel: { EMAIL, PASSWORD },
  //   auth: { LOGIN }
  // } = strings;
  const { EMAIL, PASSWORD } = strings.inputLabel;
  const { LOGIN } = strings.auth;

  const [form, setForm] = useState({
    email: {
      value: '',
      error: false,
    },
    password: {
      value: '',
      error: false,
    },
    error: null,
  });

  const [open, setOpen] = useState(false);

  /*
   ****************************************************************************
   * @brief  (attemptLogin)      tries to login with the current credentials in the state
   * @param{type : object } (e)
   *
   * @returns undefined
   ****************************************************************************
   */
  const attemptLogin = async (e) => {
    e.preventDefault();
    if (ValidateEmail(form.email.value) && form.password.value !== '') {
      setLoading(true);
      await loginWithEmailPassword(
        form.email.value,
        form.password.value,
        history
      ).catch((err) => {
        // eslint-disable-next-line no-alert
        window.alert(err.message);
        setLoading(false);
      });
    }
    setForm({ ...form, error: 'Please check errors' });
  };

  /*
   ****************************************************************************
   * @brief  (handleInput)     called each time an input value is changed in
   * the form, makes appropriate changes in state when a value in input field
   * is changed.
   * @param{type : event } (event)
   *
   * @returns undefined
   ****************************************************************************
   */
  const handleInput = (event) => {
    let error = false;
    if (event.target.name === 'email') {
      error = !ValidateEmail(event.target.value);
    }
    setForm({
      ...form,
      [event.target.name]: { value: event.target.value, error },
    });
  };

  function handleForgetPassword(event) {
    event.preventDefault();
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // returns the content of Login page
  return (
    <>
      <UnAuthenticated>
        <LoginForm {...props}>
          <Row justify="center">
            <Span size="24px" weight="bold">
              Login
            </Span>
          </Row>
          <StyledForm>
            <TextField
              fullWidth
              error={form.email.error}
              required
              label={EMAIL}
              id="email"
              type="email"
              name="email"
              onChange={handleInput}
              autoComplete="email"
            />
            <Blank />
            <TextField
              error={form.password.error}
              required
              fullWidth
              label={PASSWORD}
              id="password"
              type="password"
              name="password"
              onChange={handleInput}
              autoComplete="current-password"
            />

            <RowCenterChild>
              <StyledOutlineButton
                variant="contained"
                justify="center"
                color="primary"
                type="submit"
                onClick={attemptLogin}
                disabled={loading}
                {...props}
              >
                {loading ? (
                  <CircularProgress size={15} style={{ padding: 8 }} />
                ) : (
                  <Span color={color.white}>{LOGIN}</Span>
                )}
              </StyledOutlineButton>
            </RowCenterChild>
            <Row justify="center">
              <Link
                component="button"
                variant="body2"
                onClick={handleForgetPassword}
              >
                Forgot Password?
              </Link>
            </Row>
          </StyledForm>
        </LoginForm>
      </UnAuthenticated>
      <ADialog onClose={handleClose} open={open} width="15%">
        <ResetPassword handleDialog={handleClose} {...props} open={open} />
      </ADialog>
    </>
  );
};

export default withTheme(Login);

const Blank = styled.div`
  height: 5px;
`;

const LoginForm = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  flex: 1;
  justify-content: center;
  width: 290px;
  margin: auto;
  flex-direction: column;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 50%;
  padding: 15px 0px;
  justify-content: space-evenly;
`;

export const RowCenterChild = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
