import React from "react";

import styled from "styled-components";

import Span from "../Common/Span";
import color from "../../utils/color";
import { Row } from "../../styles/snowm_styled";

const ListTitleItem = ({ Icon, title, content, onClick, bgColor, long }) => {
  const Content = ({ fontcolor }) => {
    return (
      <StyledContent size="12px" weight="bold" color={fontcolor}>
        <StyledAbbr title={content ?? ""}>{content ?? "-"}</StyledAbbr>
      </StyledContent>
    );
  };
  return (
    <Container onClick={onClick}>
      {Icon ? <Icon /> : null}
      <StyledRow
        marginleft={Icon ? "16px" : "0"}
        marginbottom="0"
        justify="space-between"
        long={long}
        align={long && "flex-start"}
      >
        <ClickableText size="12px">{title}</ClickableText>
        {bgColor ? (
          <TypeContainer bgcolor={bgColor}>
            <Content fontcolor={color.white} />
          </TypeContainer>
        ) : (
          <Content />
        )}
      </StyledRow>
    </Container>
  );
};

const TypeContainer = styled.div`
  background-color: ${(props) => props.bgcolor};
  padding: 2px 4px;
  border-radius: 6px;
`;

const Container = styled(Row)`
  padding: 4px 0;
`;

const ClickableText = styled(Span)`
  cursor: pointer;
  margin-left: 4px;
`;

const StyledRow = styled(Row)`
  margin-left: ${(props) => props.marginleft};
  flex-direction: ${(props) => (props.long ? "column" : "row")};
`;

const StyledContent = styled(Span)`
  && {
  }
`;

const StyledAbbr = styled.abbr`
  text-decoration: none;
`;

export default ListTitleItem;
