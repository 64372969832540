import moment from "moment";

export function getRelativeDate(date) {
  return date && moment(date).fromNow();
}

export function getCurrentDate() {
  return moment().format();
}

export function getTimeInEpoch(date) {
  return moment(date).valueOf();
}

export function getFormattedDate(date) {
  return moment(date).format("MMM Do, YYYY hh:mm A");
}

export function getDayMonthYear(date) {
  return moment(date).format("MM/DD/YYYY");
}

export function getDayMonthYearTime(date) {
  return date ? moment(date).format("YYYY/MM/DD hh:mm A") : "-";
}

export function getLocaleDate(date) {
  return moment(date).format("lll");
}

export function getDateForJob(date) {
  return moment(date).format("YYYY/MM/DD hh:mm A");
}

export function getDateForMarker(date) {
  return moment(date).format("YYYY-MM-DD hh:mm A");
}
