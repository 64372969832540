import React from "react";

import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Divider, Grid, IconButton, Tooltip } from "@material-ui/core";

import { ItemCard, Row, Col } from "../../styles/snowm_styled";

export default function Card(props) {
  const {
    small,
    medium,
    large,
    children,
    onPress,
    onDeletePress,
    onEditIconPress,
    minHeight,
    onCheckIconPress,
  } = props;

  return (
    <Grid style={{ padding: "1em 0" }} item sm={small} md={medium} xl={large}>
      <ItemsCard
        boxShadow={10}
        borderRadius="10px"
        onClick={onPress}
        minheight={minHeight}
      >
        {children}
        <StyledCol>
          {(onEditIconPress || onDeletePress) && (
            <Divider style={{ marginTop: 10 }} />
          )}

          <Row justify="center">
            {onEditIconPress ? (
              <Tooltip title={onCheckIconPress ? "Re-assign crew" : "Edit"}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditIconPress();
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            {onDeletePress ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeletePress();
                  }}
                >
                  <DeleteIcon htmlColor="#173964" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Row>
        </StyledCol>
      </ItemsCard>
    </Grid>
  );
}

const ItemsCard = styled(ItemCard)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    margin: 0 10px;
    text-align: center;
    height: 100%;
    padding: 1em 1em 0 1em;
    min-height: ${(props) => props.minheight};
    cursor: pointer;
  }
`;

const StyledCol = styled(Col)`
  width: 100%;
`;
