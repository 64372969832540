import styled from 'styled-components';
import { Dialog, Divider } from '@material-ui/core';

const ADialog = styled(Dialog)`
  && {
    .MuiPaper-rounded {
      height: ${(props) => props.height};
      justify-content: ${(props) => props.justifycontent};
      width: ${(props) => (props.width ? props.width : '30%')};
      padding: ${(props) => props.padding || '8px'};
      border-radius: 1em;
      overflow: ${(props) => props.overflow || 'inherit'};
      overflow-x: hidden;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  && {
    margin-top: 8px;
  }
`;

export default ADialog;
