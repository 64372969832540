import React, { createContext, useState } from 'react';
import {
  getRealTimeRoutes,
  getRealTimeServicePoints,
  getRealTimeServices,
  editRoute
} from '../controllers/snowm_firebase';

export const RoutesContext = createContext();

export function RoutesProvider({ children }) {
  const [routes, setRoutes] = useState({});
  const [routesLoading, setRoutesLoading] = useState(null);
  const [servicePoints, setServicePoints] = useState({});
  // const [spLoading, setSpLoading] = useState(null);
  const [services, setServices] = useState(null);
  const [servicesLoading, setServicesLoading] = useState(null);

  // function _updateSP(sps) {
  //   setServicePoints(sps);
  // }

  // // fetches and updates service points real time
  // function fetchServicePoints() {
  //   setSpLoading(true);
  //   getRealTimeServicePoints(_updateSP);
  // }

  function _updateRoutes(rts) {
    // if (spLoading == null) {
    //   fetchServicePoints();
    // }
    setRoutesLoading(false);
    setRoutes(rts);
  }

  // edit a route individually
  function updateRoute(route) {
    editRoute(route);
  }

  // fetches and updates routes real time
  function fetchRoutes() {
    setRoutesLoading(true);
    getRealTimeRoutes(_updateRoutes);
  }

  function _updateServices(srvs) {
    setServicesLoading(false);
    setServices(srvs);
  }

  // fetches and updates company services real time
  function fetchServices() {
    setServicesLoading(true);
    getRealTimeServices(_updateServices);
  }

  return (
    <RoutesContext.Provider
      value={{
        routes,
        routesLoading,
        fetchRoutes,
        servicePoints,
        updateRoute,
        fetchServices,
        services,
        servicesLoading
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
}
