const color = {
  red: "#c40505",
  green: "#1e6309",
  snomwBlue: "#54bce8",
  boxShadowColor: "#b8b8b8",
  detailColor: "#110759",
  black: "#000000",
  white: "#ffffff",
  lightBlack: "#6d6868",
  dividerColor: "#fbf1f1",
  grey: "#9d9595",
  lightRed: "#ea3616",
  yellow: "#ffa500",
  gradientEndColor: "#006eb9",
  amber: "#ffbf00",
};

export default color;
