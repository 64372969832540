/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_styled.js
 *
 *  Description: Provides app wide styled components
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import styled, { css } from 'styled-components';
import { Box, Typography } from '@material-ui/core';

export const CenterScreen = styled.div`
  color: ${(props) => props.theme.secondary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.form``;
export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SmallLogo = styled.div`
  flex: 1;
  margin: auto;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const Padding = styled.div`
  padding: ${(props) => props.value}px;
  padding-left: ${(props) => props.left}px;
  padding-right: ${(props) => props.right}px;
  padding-top: ${(props) => props.top}px;
  padding-bottom: ${(props) => props.bottom}px;
`;

export const PaddedDescText = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
`;

export const FloatingButton = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 20px;
  z-index: 10;
`;

export const Row = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  margin-bottom: ${(props) => props.marginbottom || '6px'};
  justify-content: ${(props) => props.justify || 'inherit'};
  align-items: ${(props) => props.align || 'center'};
  gap: ${(props) => props.gap}
    ${(props) =>
      props.vertCenter &&
      css`
        align-items: center;
      `};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  width: ${(props) => props.width};
`;

export const FormContainer = styled.div``;

export const ItemCard = styled(Box)`
  && {
    height: 100%;
    min-height: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StyledTypography = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
}))`
  && {
    margin: 4px;
    opacity: ${(props) => props.opacity};
    font-size: 16px;
    color: ${(props) => props.textcolor};
    font-weight: ${(props) => props.weightoffont};
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  && {
    margin-top: ${(props) => props.margintop ?? '12px'};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 95%;
    font-size: 1.5em;
    font-weight: 700;
  }
`;
