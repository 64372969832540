import React from 'react';

import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import CalendarIcon from 'mdi-react/CalendarIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';

import { getDateForMarker } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import { Title, Row, Col } from '../../../styles/snowm_styled';

const MarkerBasicDetail = ({ marker, property }) => {
  function getDate(date) {
    return date ? getDateForMarker(date) : 'Never';
  }

  function renderProperty() {
    if (!property) return null;
    return (
      <>
        <ListTitleItem
          Icon={HomeCityOutlineIcon}
          content={property?.name}
          title="Property"
        />
        <ListTitleItem
          Icon={MapMarkerIcon}
          content={property?.address}
          title="Property Address"
          long
        />
      </>
    );
  }

  const ActivityType = () => {
    if (!marker?.activityType) return null;

    return (
      <ListTitleItem
        Icon={OrderBoolAscendingVariantIcon}
        content={marker?.activityType}
        title="Activity Type"
      />
    );
  };

  return (
    <StyledCol justify="space-between" align="center">
      <StyledRow justify={marker?.beaconId ? 'center' : 'space-between'}>
        <Title margintop="0" variant="h6">
          {marker?.name}
        </Title>
      </StyledRow>
      <StyledDivider />

      <StyledCol justify="flex-start">
        <ActivityType />

        {renderProperty()}

        <ListTitleItem
          Icon={CalendarIcon}
          content={getDate(marker?.lastServed)}
          title="Last Served"
        />
      </StyledCol>
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  flex: 1;
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

export default MarkerBasicDetail;
