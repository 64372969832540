import React from "react";

import { Switch, Route } from "react-router";

import ServiceRequests from "./ServiceRequests";
import WorkOrderDetails from "./WorkOrderDetails";

export default function () {
  return (
    <Switch>
      <Route
        path="/home/serviceRequests/marker/:markerKey/order/:orderId"
        component={WorkOrderDetails}
        exact
      />
      <Route path="/home/serviceRequests" component={ServiceRequests} exact />
    </Switch>
  );
}
