import React, { useEffect, useState, useRef, useContext } from "react";

import moment from "moment";
import styled from "styled-components";
import InfoIcon from "mdi-react/InfoVariantIcon";
import AlertIcon from "mdi-react/AlertOutlineIcon";
import BellOutline from "mdi-react/BellOutlineIcon";
import InfoErrorIcon from "mdi-react/InformationOutlineIcon";

import Map from "../Maps/snowm_maps";
import Typography from "../../Common/Span";
import Loader from "../../Generics/snowm_loader";
import { Row, Col } from "../../../styles/snowm_styled";
import { ServiceTypesContext } from "../../../contexts/service_types";
import { LocalizationContext } from "../../../contexts/localization_context";
import {
  getRealTimeLocationOfAllProviders,
  getRealTimeLogs,
} from "../../../controllers/snowm_firebase";
import {
  Log,
  LoadContainer,
  TitleContainer,
  VerticalScroll,
  MapContainer,
  StyledCard,
  MapStyledCard,
} from "./styled";

export default function Home({ theme }) {
  const [latLng, setLatLng] = useState();
  const [crewsLocations, setCrewsLocations] = useState();
  const [logs, setLogs] = useState();

  const { strings } = useContext(LocalizationContext);

  const data = useContext(ServiceTypesContext);

  const { markers, properties } = data;

  const {
    home: { NOTIFICATIONS },
  } = strings;

  const logsRef = useRef();

  useEffect(() => {
    let isSubscribe = true;

    function onChange(loc) {
      if (isSubscribe) setCrewsLocations(loc);
    }

    getRealTimeLocationOfAllProviders(markers, onChange);

    getRealTimeLogs(markers).then((res) => {
      if (isSubscribe) setLogs(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [markers]);

  useEffect(() => {
    if (crewsLocations && properties && markers) {
      setLatLng([...crewsLocations, ...properties, ...markers]);
    }
  }, [crewsLocations, properties, markers]);

  const getNotifIcon = (type) => {
    switch (type) {
      case "error":
        return <InfoErrorIcon color="red" opacity={0.7} size={24} />;

      case "info":
        return <InfoIcon color="blue" opacity={0.7} size={24} />;
      case "warning":
        return <AlertIcon color="#ffc107" opacity={0.7} size={24} />;
      default: {
        return <BellOutline size={24} />;
      }
    }
  };

  function getLogsList(log) {
    if (log && log?.length !== 0) {
      return log.map((l) => {
        return (
          <Log key={l.date}>
            <Row justify="space-between">
              <JobStatus weight="bold">{l.title}</JobStatus>
              <Typography size="16px" color="primary">
                {moment(l.date).format("MMMM DD, YYYY, h:mm A")}
              </Typography>
            </Row>
            <Row align="center">
              <StyledRow width="auto">{getNotifIcon(l.logType)}</StyledRow>
              <Typography size="16px">{l.message}</Typography>
            </Row>

            <div ref={logsRef} />
          </Log>
        );
      });
    }
    return (
      <LoadContainer>
        <Typography> No logs </Typography>
      </LoadContainer>
    );
  }

  function getLogsContainer(t) {
    return (
      <>
        <TitleContainer>
          <StyledTitle size="2em" weight="bold">
            {NOTIFICATIONS}
          </StyledTitle>
        </TitleContainer>
        <VerticalScroll>{getLogsList(logs, t)}</VerticalScroll>
      </>
    );
  }

  if (!markers || !properties || !latLng || !logs) return <Loader />;

  return (
    <StyledContainer>
      <StyledMapContainer margintop="0">
        <StyledLogsCard>{getLogsContainer(theme)}</StyledLogsCard>
        <MapStyledCard width="100%">
          <Map
            dashboard
            locations={latLng}
            center={[45.347767, -75.90983]}
            zoom={9}
          />
        </MapStyledCard>
      </StyledMapContainer>
    </StyledContainer>
  );
}

const StyledMapContainer = styled(MapContainer)`
  height: 100%;
`;

const StyledRow = styled(Row)`
  margin-right: 8px;
`;

const StyledContainer = styled(Col)`
  padding: 35px;
`;

const StyledLogsCard = styled(StyledCard)`
  flex-basis: 80%;
`;

const StyledTitle = styled(Typography)`
  && {
    margin-top: 16px;
  }
`;

const JobStatus = styled(Typography)`
  && {
    margin-right: 8px;
  }
`;
