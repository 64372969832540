const en = {
  validation: {},
  auth: {
    LOGIN: "Login",
    LOG_OUT: "Log Out",
  },
  inputLabel: {
    EMAIL: "Email",
    PASSWORD: "Password",
    NAME: "Name",
    PHONE: "Phone",
    ADDRESS: "Address",
    CONTACT_NUMBER: "Contact Number",
    DESIGNATION: "Designation",
  },
  sidemenu: {
    HOME: "Home",
    JOBS: "Jobs",
    CREW: "Crew",
    SERVICES: "Services",
    ROUTES: "Routes",
    MARKERS: "Markers",
    REPORTS: "Reports",
    COMPANY_PROFILE: "Profile",
    DEVICES: "Devices",
    ATTENDANCE: "Attendance",
    USER_PROFILE: "User Profile",
  },
  jobType: {
    ASSIGNED_JOBS: "Assigned Jobs",
    PERIODIC_JOBS: "Periodic Jobs",
  },
  action: {
    CREATE_JOB: "Create Job",
    UPDATE_ROUTE: "Update Route",
    EDIT_ROUTE: "Edit Route",
    EDIT: "Edit",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    ADD: "Add",
  },
  location: {
    CURRENT_LOCATION: "Current Location",
  },
  detail: {
    CREW_DETAIL: "Crew Detail",
    ISSUE_REPORTS: "Issue Reports",
  },
  messages: {
    NO_CREWS_ADDED: "No crew member has been added",
    MINIMUM_MARKERS:
      "There are not enough markers on the route to be assigned to a crew. Please add at least 3 points",
    YES: "Yes",
    NO: "No",
    NO_MESSAGES: "No messages",
    NOT_AVAILABLE: "Not available",
  },
  search: {
    SEARCH: "Search",
  },
  home: {
    NOTIFICATIONS: "Notifications",
    SEE_MORE_INFO: "See More Info",
  },
  status: {
    ASSIGNED: "Assigned",
    COMPLETED: "Completed",
    STARTED: "Started",
  },
  markers: {
    LAST_SERVED: "Last Served",
    MARKER_NAME: "Marker Name",
    SELECTED_MARKERS: "Selected Markers",
    SELECT_MARKER: "Select Marker",
    MARKER_LOGS: "Marker Logs",
    SERVED_MARKERS: "Served Markers",
  },
  misc: {
    CHANGE_LANGUAGE: "Change Language",
    LANGUAGE_SETTINGS: "Language Settings",
    DISTANCE: "Distance",
    METERS: "meters",
    LAST_UPDATED: "Last Updated",
    MAP_VIEW: "Map View",
    CHANGE_PASSWORD: "Change Password",
  },
  serviceType: {
    INDOOR: "Indoor",
  },
  image: {
    AFTER_IMAGE: "After Image",
    BEFORE_IMAGE: "Before Image",
  },
  time: {
    COMPLETED_AT: "Completed at",
  },
};

export default en;
