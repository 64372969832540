/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_theme.js
 *
 *  Description: Provides app wide theme
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { pink, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#29abe2';
export const primaryLight = '#54bce8';
export const primaryDark = '#173964';
export const grey = '#a3a3a3';
export default createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: primaryLight,
      dark: primaryDark,
      contrastText: '#fff',
    },

    secondary: pink,
    error: red,
    grey: {
      main: grey,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: '"Montserrat"',
    fontSize: 18,
    subheading: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.8,
      letterSpacing: 0.2,
    },
    title: {
      fontWeight: 600,
    },
    subtitle: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: '0.875rem',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        height: undefined,
      },
    },
  },
});
