import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';

import Span from '../../Common/Span';
import RequestItem from './RequestItem';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import Loader from '../../Generics/snowm_loader';
import AddServiceRequest from './AddServiceRequest';
import { Row, Title } from '../../../styles/snowm_styled';
import { AuthContext } from '../../../contexts/auth_context';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  deleteServiceRequest,
  getServiceRequestsOfCustomer,
} from '../../../controllers/snowm_firebase';

const ServiceRequests = () => {
  const data = useContext(ServiceTypesContext);

  const { markers } = data;

  const authDetails = useContext(AuthContext);

  const { user } = authDetails;

  const [openDialog, setOpenDialog] = useState(false);
  const [serviceRequests, setServiceRequests] = useState();
  const [deleting, setDeleting] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [deletingWarning, setDeletingWarning] = useState(false);

  useEffect(() => {
    let isCancel = false;
    getServiceRequestsOfCustomer(user?.user_id).then((res) => {
      if (!isCancel) {
        setServiceRequests(res);
      }
    });

    return () => {
      isCancel = true;
    };
  }, [user]);

  function handleAddButton() {
    setOpenDialog(true);
    setSelectedRequest(null);
  }

  function handleCancel() {
    setOpenDialog(false);
  }

  function handleDeleteIconClick(request) {
    setDeletingWarning(true);
    setSelectedRequest(request);
  }

  function handleWarningDeleteCancel() {
    setDeletingWarning(false);
  }

  async function handleServiceRequestDeletion() {
    setDeleting(true);
    try {
      await deleteServiceRequest(selectedRequest);
      const remainingServiceRequests = serviceRequests?.filter(
        (eachRequest) => eachRequest.id !== selectedRequest.id
      );
      setServiceRequests(remainingServiceRequests);
      setDeleting(false);
      setDeletingWarning(false);
    } catch (error) {
      console.log('Error', error);
    }
  }

  function handleRequestAddedOrEdited(request) {
    if (selectedRequest) {
      const updatedServiceRequests = serviceRequests?.filter(
        (eachRequest) => eachRequest.id !== request.id
      );
      setServiceRequests([request, ...(updatedServiceRequests ?? [])]);
    } else {
      setServiceRequests([request, ...serviceRequests]);
    }
  }

  function handleEditClick(requestSelected) {
    setSelectedRequest(requestSelected);
    setOpenDialog(true);
  }

  if (!serviceRequests || !data) {
    return <Loader />;
  }

  return (
    <Container>
      <Row>
        <Title>Service Requests</Title>
      </Row>
      {serviceRequests?.length === 0 ? (
        <Row justify="center">
          <Span>No service request</Span>
        </Row>
      ) : (
        <Grid>
          {serviceRequests.map((request) => (
            <RequestItem
              request={request}
              key={request.id}
              handleDelete={() => handleDeleteIconClick(request)}
              handleEdit={() => handleEditClick(request)}
            />
          ))}
        </Grid>
      )}
      {data?.subscribedServices?.markers && (
        <AddButton title="New Request" onPress={handleAddButton} />
      )}
      <AddServiceRequest
        markers={markers}
        customerUid={user?.user_id}
        open={openDialog}
        handleAddedOrEditedRequest={handleRequestAddedOrEdited}
        handleCancel={handleCancel}
        selectedRequest={selectedRequest}
      />
      <AlertBox
        open={deletingWarning}
        title="Are you sure you want to delete this service request?"
        handleNegativeAction={handleWarningDeleteCancel}
        handlePositiveAction={handleServiceRequestDeletion}
        loading={deleting}
      />
    </Container>
  );
};

export default ServiceRequests;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;
`;

const Container = styled.div`
  margin: 12px;
`;
