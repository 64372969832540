import React from "react";

import ADialog from "../../../Common/styled";
import ChangePassword from "../../../Public/ChangePassword";

const ChangePasswordModal = ({ open, handleSnackbar, onClose }) => {
  return (
    <ADialog open={open}>
      <ChangePassword
        fromProfile
        handleSnackbar={handleSnackbar}
        onClose={onClose}
      />
    </ADialog>
  );
};

export default ChangePasswordModal;
