/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_appbar.jsx
 *
 *  Description: Appbar component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import Menu from "@material-ui/core/Menu";
import LogoutIcon from "mdi-react/LogoutIcon";
import { AppBar, MenuItem, Toolbar, Typography } from "@material-ui/core";

import { logo } from "../../helpers/misc";
import { getCurrentUser, logout } from "../../controllers/snowm_firebase";
import { LocalizationContext } from "../../contexts/localization_context";

// functional component
/*
 **************************************************************************************
 * @brief  (AppBarView)  renders the appbar
 * @param{type :object } (props)
 *
 * @returns Appbar component (react element)
 **************************************************************************************
 */
function AppBarView({ ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState("Snowm");
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*
   **************************************************************************************
   * @brief  (GetCurrenUser)  sets the text inside the userProfile image if image not provided
   * @param{type :Firebase.user } (user)
   *
   * @returns undefined
   **************************************************************************************
   */

  getCurrentUser((user) => {
    if (user) {
      setUserName(user.displayName);
    } else {
      setUserName("Snowm");
    }
  });

  const open = Boolean(anchorEl);
  const { theme, routes, history } = props;
  const { strings } = useContext(LocalizationContext);

  return (
    <AppBar position="static" theme={theme} color="inherit">
      <ToolbarStyled>
        <LogoImage onClick={() => {}} src={logo} height={64} width={128} />
        <div>
          <StyledMenu
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            theme={theme}
          >
            <IconText
              style={{
                margin: "auto",
              }}
            >
              {userName ? userName[0] : "S"}
            </IconText>
          </StyledMenu>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            {routes.map(({ route, name, icon, menu }) => {
              if (menu) {
                return (
                  <MenuItem
                    key={name}
                    onClick={() => {
                      history.push(route);
                      handleClose();
                    }}
                  >
                    <Icon>{icon}</Icon>
                    {name}
                  </MenuItem>
                );
              }
              return null;
            })}
            <MenuItem onClick={logout}>
              <Icon>
                <LogoutIcon />
              </Icon>
              {strings?.auth?.LOG_OUT}
            </MenuItem>
          </Menu>
        </div>
      </ToolbarStyled>
    </AppBar>
  );
}

// proptype validation
AppBarView.defaultProps = {
  toggle: () => {},
};

AppBarView.propTypes = {
  toggle: PropTypes.func,
};

export default AppBarView;

const Icon = styled(Typography)`
  display: flex;
  margin-right: 10px;
`;

const ToolbarStyled = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledMenu = styled.div`
  && {
    cursor: pointer;
    display: flex;
    height: 20px;
    width: 20px;
    padding: 10px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.primary.main};
    color: white;
  }
`;

const IconText = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const LogoImage = styled.img`
  object-fit: cover;
  cursor: pointer;
  height: 64px;
  padding: 8px 0px;
`;
