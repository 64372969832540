import React from "react";

import styled from "styled-components";
import { Card, Button } from "@material-ui/core";

import { logo } from "../../helpers/misc";
import { primaryDark } from "../../styles/snowm_theme";

const UnAuthenticated = ({ fromProfile, children }) => {
  if (fromProfile) {
    return <>{children}</>;
  }

  return (
    <Overlay>
      <StyledCard>
        <LogoImage src={logo} />
        {children}
      </StyledCard>
    </Overlay>
  );
};

export default UnAuthenticated;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  display: flex;
  background-color: ${primaryDark};
`;

const StyledCard = styled(Card)`
  margin: auto;
  width: 450px;
  min-height: 300px;
  padding: 10px;
  padding: 20px 0px;
  && {
    border-radius: 35px;
  }
  display: flex;
  flex-direction: column;
`;

const LogoImage = styled.img`
  object-fit: cover;
  width: 230px;
  height: 115px;
  align-self: center;
`;

export const StyledOutlineButton = styled(Button)`
  && {
    :hover {
      border-width: 2px;
    }
    width: ${(props) => props.width ?? "100%"};
    color: ${(props) => props.theme.palette.grey.main};
    font-weight: bold;
    align-self: center;
    border-color: ${(props) => props.theme.palette.primary.dark};
    border-width: 2px;
    border-radius: 4px;
    text-transform: none;
    color: white;
    padding: 8px 25px;
    margin: 24px 0px;
    flex-basis: ${(props) => props.flexbasis ?? "315px"};
    background-image: linear-gradient(to right, #00afee, #006eb9);
  }
`;

export const passwordRange = {
  minLength: 7,
  maxLength: 25,
};
