/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_drawer.jsx
 *
 *  Description: Drawer component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import styled from 'styled-components';
import ExpandIcon from 'mdi-react/ChevronDownIcon';
import React, { useState, useEffect } from 'react';
import CollapseIcon from 'mdi-react/ChevronRightIcon';
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography
} from '@material-ui/core';

// functional component
/*
 ****************************************************************************
 * @brief  (DrawerView)  Renders the contents of drawer
 * @param{type : Array,string,object } ( routes,active,props)
 *
 * @returns content of the drawer (react element)
 ****************************************************************************
 */
function DrawerView({ routes, active, staticContext, ...props }) {
  const [expanded, setExpanded] = useState(active);
  const [activeLocal, setActive] = useState(active);
  useEffect(() => {
    setActive(active);
    setExpanded(active);
  }, [active]);

  /*
   **************************************************************************************
   * @brief  (SideItems)  renders the items inside the drawer
   * @param{type : Array } (routesToRender)
   *
   * @returns Appropriate React element including all the routes listed in routesToRender
   **************************************************************************************
   */
  function SideItems({ routes: routesToRender }) {
    return (
      <List>
        {routesToRender.map(({ route, name, icon, children, menu, hide }) => {
          const routeAbs = `${route}`;
          const expand = expanded.includes(route, 0);
          if (menu) {
            return null;
          }
          if(hide) {
            return null;
          }

          return (
            <div key={name} style={{ padding: 0 }}>
              <StyledListItem
                active={activeLocal === route ? '1' : undefined}
                button
                onClick={() => {
                  setActive(route);
                  if (children) {
                    if (!expand) {
                      setExpanded(route);
                    } else {
                      setExpanded('*');
                    }
                  }
                  props.history.push(routeAbs);
                }}
                {...props}
              >
                <PaddedListItemIcon size="large">{icon}</PaddedListItemIcon>
                <StyledContainer>
                  <StyledListItemText className="waves-effect">
                    <Typography
                      style={{ fontWeight: '600' }}
                    >
                      {name.toUpperCase()}
                    </Typography>
                  </StyledListItemText>
                  {children ? (
                    <ListItemIcon
                      style={{ margin: 'auto' }}
                      active={activeLocal === route}
                    >
                      {expand ? <CollapseIcon /> : <ExpandIcon />}
                    </ListItemIcon>
                  ) : (
                    <div />
                  )}
                </StyledContainer>
                <Divider />
              </StyledListItem>
              {children && expand ? <SideItems routes={children} /> : null}
            </div>
          );
        })}
      </List>
    );
  }

  // returns the Content of SideItems inside of sidebar
  return (
    <div>
      <SideBar {...props}>
        <SideItems routes={routes} />
      </SideBar>
    </div>
  );
}

export default DrawerView;

const StyledListItem = styled(ListItem)`
  && {
    padding: 0px;
    padding-left: 20px;
    background: ${props =>
      props.active
        ? props.theme.palette.primary.light
        : 'transparent'} !important;
    Typography,
    div {
      color: white;
    }
    &:hover {
      background: ${props => props.theme.palette.primary.light};
    }
  }
`;

const PaddedListItemIcon = styled(ListItemIcon)`
  padding: 10px;
  && {
    min-width: 20px;
    margin: 0px;
  }
`;

const StyledListItemText = styled(ListItemText)`
  && {
    Typography {
      font-size: 18px;
    }
    padding-left: 0px;
    width: 100%;
  }
`;

const SideBar = styled.div`
  background-color: #29abe2;
  flex-grow: 1;
  height: 100%;
  width: ${props => `${props.width}px`};
`;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-left: 0px;
`;
