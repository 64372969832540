// eslint-disable-next-line
export const getFilteredItems = (items, selectedServiceKey) => {
  if (!items) {
    return [];
  }
  if (selectedServiceKey === 'all') {
    return items;
  }
  return Object.values(items).filter(
    (item) => (item.serviceKey || item.job?.serviceKey) === selectedServiceKey
  );
};
export const getSubscribedServices = (subscribed) => {
  if (!subscribed) return [];
  const data = [];
  const subscribedTypes = Object.keys(subscribed);
  if (!subscribedTypes) return [];
  subscribedTypes.forEach((type) => {
    if (subscribed[type]) {
      data.push(type);
    }
  });
  return data;
};
export const getSubscribedServiceTypes = (subscribed) => {
  if (!subscribed) {
    return [];
  }
  const data = [];
  const subscribedTypes = Object.keys(subscribed);
  if (!subscribedTypes) return [];
  subscribedTypes.forEach((type) => {
    if (type !== 'reports' && subscribed[type]) {
      data.push(type);
    }
  });
  return data;
};

export const getFilterTypes = (subscribed) => {
  const subscribedTypes = getSubscribedServiceTypes(subscribed);
  if (!subscribedTypes) return [];
  const capsType = subscribedTypes.map((type) => type.toUpperCase());
  const allSubscribedTypes = ['ALL', ...capsType];
  return allSubscribedTypes;
};

export const logo = 'https://i.ibb.co/K9fsvpx/Stratosfy-Marker-Logo-01.png';

export const routesIncludingServiceTypes = ['indoor', 'outdoor'];

export function getFirstLetterCapitilizedWord(word = '') {
  const lowerCaseWord = word.toLowerCase();
  const firstLetter = lowerCaseWord.split('')[0].toUpperCase();
  const remainingLetter = lowerCaseWord.slice(1);
  return `${firstLetter}${remainingLetter}`;
}
