import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../contexts/auth_context";
import Loader from "../Components/Generics/snowm_loader";

// alternative = (string) route to be redirected to if the authentication go as expected
// shouldFail = (bool) to represent if failed authentication is expected

function renderProps(user, shouldFail, altPath, Component, props) {
  if (user === null) {
    return <Loader />;
  }

  const isUser = Object.keys(user ?? {}).length > 0;
  if ((isUser && !shouldFail) || (!isUser && shouldFail)) {
    return <Component {...props} />;
  }
  return <Redirect to={altPath} />;
}

function AuthenticatedRoute(props) {
  return (
    <AuthContext.Consumer>
      {({ user }) => <AuthRoute user={user} {...props} />}
    </AuthContext.Consumer>
  );
}

function AuthRoute({
  shouldFail,
  altPath,
  component: Component,
  user,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        renderProps(user, shouldFail, altPath, Component, props)
      }
    />
  );
}
AuthenticatedRoute.propTypes = {
  shouldFail: PropTypes.bool.isRequired,
  altPath: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

export const PrivateRoute = (props) => (
  <AuthenticatedRoute altPath="/login" shouldFail={false} {...props} />
);
export const LoginRoute = (props) => (
  <AuthenticatedRoute altPath="/home" shouldFail {...props} />
);
