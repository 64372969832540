import React from "react";

import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import Span from "../../Common/Span";
import color from "../../../utils/color";
import { Row } from "../../../styles/snowm_styled";
import PrimaryButton from "../../Common/primary_button";

const Button = ({ loading, emptyFieldExist, handleButtonClick, text, anchor }) => {
  return (
    <StyledRow justify={anchor ?? 'center'}>
      <PrimaryButton
        disabled={emptyFieldExist}
        loading={loading}
        onClick={handleButtonClick}
      >
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <Span color={color.white}>{text}</Span>
        )}
      </PrimaryButton>
    </StyledRow>
  );
};

export default Button;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;
