import React, { useEffect, useState } from 'react';

import ArrowLeft from 'mdi-react/ArrowLeftBoldIcon';
import { Typography, Grid } from "@material-ui/core";

import { useHistory } from 'react-router';
import Card from "../../Common/custom_card";
import Button from '../serviceLogs/FetchButton';
import Loader from "../../Generics/snowm_loader";
import { Row, Title } from "../../../styles/snowm_styled";
import MarkerBasicDetail from "../markers/marker_basic_detail";
import {
  getMarkersByPropertyKey,
  getPropertyById,
} from '../../../controllers/snowm_firebase';

const PropertyMarkers = ({ match }) => {
  const { key } = match?.params;

  const [markers, setMarkers] = useState();
  const [property, setProperty] = useState();
  const history = useHistory();

  useEffect(() => {
    let isSubscribe = true;
    getMarkersByPropertyKey(key).then((res) => {
      if (isSubscribe) setMarkers(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [match]);

  useEffect(() => {
    let isSubscribe = true;
    getPropertyById(key).then((res) => {
      if (isSubscribe) setProperty(res[0]);
    });

    return () => {
      isSubscribe = false;
    };
  }, [match]);

  if (!markers || !property) {
    return <Loader />;
  }

  if (markers.length === 0) {
    return (
      <Row justify="center">
        <Typography>No markers.</Typography>
      </Row>
    );
  }

  return (
    <>
      <Button handleButtonClick={() => history.goBack()} text={<ArrowLeft />} anchor="left" />
      <Row justify="center">
        <Title>{property?.name}</Title>
      </Row>
      <Grid container>
        {markers?.map((marker) => (
          <Card key={marker.key} small={6} medium={4} large={3}>
            <MarkerBasicDetail
              marker={marker}
              property={property}
              fromPropertySubPage
            />
          </Card>
        ))}
      </Grid>
    </>
  );
};

export default PropertyMarkers;
