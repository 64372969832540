import React, { useState, useEffect } from "react";

import styled from "styled-components";

import InfoWindow from "./InfoWindow";
import markerImg from "../../../assets/images/redMarker.png";
import greenMarker from "../../../assets/images/greenMarker.png";
import { getProviderByUid } from "../../../controllers/snowm_firebase";

const height = 50;
const width = 50;

export default function MapMarker({
  onTap,
  assigned,
  point,
  edit,
  show,
  dashboard,
}) {
  const [image, setImage] = useState();
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    let isCancel = false;
    if (point?.uid && dashboard) {
      getProviderByUid(point?.uid).then((res) => {
        if (!isCancel) setImage(res?.imageUrl);
      });
    } else if (point?.customer || point?.customer === null) {
      if (point?.imageUrl) setImage(point?.imageUrl);
      else setImage(markerImg);
    } else if (assigned === true) {
      setImage(greenMarker);
    } else {
      setImage(greenMarker);
    }

    return () => {
      isCancel = true;
    };
  }, [assigned, point]);

  useEffect(() => {
    if (show) setShowInfo(true);
    else setShowInfo(false);
  }, [show]);

  return (
    <>
      {showInfo && point && (
        <InfoWindow
          point={point}
          onClose={() => {
            setShowInfo(false);
          }}
        />
      )}
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          if (onTap) {
            onTap();
          }
          if (!edit) {
            setShowInfo(true);
          }
        }}
        style={{ position: "relative" }}
        onKeyDown={() => {}}
      >
        <StyledImage
          src={image}
          height={height}
          width={width}
          onError={(e) => {
            e.target.src =
              "https://iupac.org/wp-content/uploads/2018/05/default-avatar.png";
          }}
        />
      </div>
    </>
  );
}

const StyledImage = styled.img`
  position: absolute;
  left: ${(props) => props.left ?? -(width / 2)}px;
  top: ${(props) => props.top ?? -height}px;
  border-radius: 50%;
  object-fit: cover;
`;
