import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Card, Typography, Collapse, Grid } from '@material-ui/core';

import { BoldTitle } from './styled';
import { Row, Col } from '../../../styles/snowm_styled';
import { getRelativeDate } from '../../../helpers/date';
import { getServicePointById } from '../../../controllers/snowm_firebase';

const IssueReports = ({ crew, report, handleReportClick }) => {
  const [marker, setMarker] = useState();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    let isSubscribe = true;
    if (report?.markerKey) {
      getServicePointById(report?.markerKey).then((response) => {
        if (isSubscribe) setMarker(response);
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item xs={4}>
      <Row>
        <StyledCard onClick={handleReportClick}>
          <Col>
            <BoldTitle variant="h6">{report.type}</BoldTitle>
            <Row justify="space-between">
              <Typography>Reported By</Typography>
              <BoldTitle>{crew}</BoldTitle>
            </Row>
            <Row justify="space-between">
              <Typography>Reported Message</Typography>
              <BoldTitle>{report.message}</BoldTitle>
            </Row>
            {marker && (
              <Row justify="space-between">
                <Typography>Reported From</Typography>
                <BoldTitle>{marker?.name ?? '-'}</BoldTitle>
              </Row>
            )}
            <Row justify="space-between">
              <Typography>Reported</Typography>
              <BoldTitle>{getRelativeDate(report.date)}</BoldTitle>
            </Row>
            <Row justify="space-between">
              <Typography>Address</Typography>
              <BoldTitle>{report.address ?? '-'}</BoldTitle>
            </Row>
            <Row justify="space-between">
              <Row>
                <Typography>Images Attached</Typography>
                <BoldTitle>{report.imageUrls?.length ?? 0}</BoldTitle>
              </Row>
              <StyledChevronIcon size={40} onClick={handleExpandClick} />
            </Row>
            <Collapse in={expanded}>
              <Grid container spacing={3}>
                {report.imageUrls ? (
                  report.imageUrls.map((imgUrl) => {
                    return (
                      <Grid key={imgUrl} item xs={6}>
                        <a
                          href={imgUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            key={imgUrl}
                            height="150px"
                            width="100%"
                            src={imgUrl}
                            alt="incident report"
                          />
                        </a>
                      </Grid>
                    );
                  })
                ) : (
                  <Row justify="center">
                    <Typography>No image</Typography>
                  </Row>
                )}
              </Grid>
            </Collapse>
          </Col>
        </StyledCard>
      </Row>
    </Grid>
  );
};

export default IssueReports;

const StyledCard = styled(Card)`
  padding: 16px;
  margin: 10px 0;
  width: 500px;
  cursor: pointer;
`;

const StyledChevronIcon = styled(ChevronDownIcon)`
  cursor: pointer;
  margin-bottom: 8px;
`;
