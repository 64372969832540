import React, { useState } from "react";

import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import Marker from "./snowm_marker";

function Map({ single, center, zoom, locations, dashboard }) {
  const [mapInfo, setMapInfo] = useState({
    loaded: false,
    map: null,
    maps: null,
  });

  function onMapLoaded(m, ms) {
    setMapInfo({
      loaded: true,
      map: m,
      maps: ms,
    });
  }

  function renderMarkers(locObjs) {
    if (locObjs) {
      const locs = Object.values(locObjs);
      if (locs.length !== 0) {
        return locs.map((loc) => {
          if (loc) {
            if (mapInfo.map) {
              mapInfo.map.setCenter({
                lat: loc.latitude,
                lng: loc.longitude,
              });
            }

            return (
              <Marker
                key={loc.key || loc.uid}
                lat={loc.latitude}
                lng={loc.longitude}
                point={loc}
                dashboard={dashboard}
              />
            );
          }

          if (single) {
            return <NoInfo>No Location Information</NoInfo>;
          }
          return <></>;
        });
      }
    }
    return <></>;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
      defaultCenter={center}
      defaultZoom={zoom}
      onGoogleApiLoaded={({ map: m, maps: ms }) => onMapLoaded(m, ms)}
      yesIWantToUseGoogleMapApiInternals
      distanceToMouse={() => {}}
    >
      {renderMarkers(locations)}
    </GoogleMapReact>
  );
}

Map.defaultProps = {
  markers: [
    { lat: 53.42728, lng: -6.24357 },
    { lat: 43.681583, lng: -79.61146 },
  ],
  center: [47.367347, 8.5500025],
  zoom: 4,
};

export default Map;

const NoInfo = styled.div`
  color: #d8000c;
  font-style: italic;
  font-size: 16px;
`;
