/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: alert_box.jsx
 *
 *  Description: Alert Box Component
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 22-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Button,
  DialogActions,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';

import ADialog from './styled';
import PrimayButton from './primary_button';
import { LocalizationContext } from '../../contexts/localization_context';

export default function FormModal({
  open,
  handleNegativeAction,
  title,
  edit,
  handlePositiveAction,
  loading,
  scroll,
  children,
  renderActions = true
}) {
  const { strings } = useContext(LocalizationContext);
  const {
    action: { EDIT, ADD, CANCEL }
  } = strings;
  return (
    <ADialog
      open={open}
      onClose={handleNegativeAction}
      fullWidth
      maxwidth="600"
      overflow="auto"
    >
      <StyledForm scroll={scroll} onSubmit={handlePositiveAction}>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        {children}

        {renderActions && (
          <StyledDialogActions>
            <Button
              style={{ fontSize: 16, fontWeight: '700', marginRight: 10 }}
              onClick={handleNegativeAction}
              color="primary"
            >
              {CANCEL}
            </Button>

            <PrimayButton type="submit" loading={loading}>
              {loading && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginRight: 8 }}
                />
              )}
              {edit ? EDIT : ADD}
            </PrimayButton>
          </StyledDialogActions>
        )}
      </StyledForm>
    </ADialog>
  );
}

const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding-left: 0px;
    h2 {
      font-weight: 700;
      font-size: x-large;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 16px 0;
  }
`;

const StyledForm = styled.form`
  position: relative;
  padding: 1rem 2rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: ${props => props.scroll && 'scroll'};
`;
