import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router';
import ArrowLeft from 'mdi-react/ArrowLeftBoldIcon';
import Button from '../serviceLogs/FetchButton';
import Loader from '../../Generics/snowm_loader';
import MarkerLogDetails from './MarkerLogDetails';
import { Title, Row } from '../../../styles/snowm_styled';
import { MarkerLogsContainer, LogContainer } from '../serviceLogs/LogDetail';
import { getMarkerLogsForMarkers } from '../../../controllers/snowm_firebase';

const MarkersLogsDetails = ({ match }) => {
  const [markerLogs, setMarkerLogs] = useState();
  const [loading, setLoading] = useState();
  const history = useHistory();
  const { key } = match.params;

  useEffect(() => {
    let isCancel = false;
    if (key) {
      getMarkerLogsForMarkers([key]).then((res) => {
        if (!isCancel) setMarkerLogs(res);
      });
    }

    return () => {
      isCancel = true;
    };
  }, [key]);

  async function handleMoreButton(e) {
    e.stopPropagation();
    setLoading(true);

    const dateOfLastMarkerLog = markerLogs[markerLogs?.length - 1]?.startDate;
    try {
      const response = await getMarkerLogsForMarkers([key], {
        endDate: dateOfLastMarkerLog,
      });
      setMarkerLogs([...markerLogs, ...response]);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }

  function renderMarkerLogs() {
    if (!markerLogs) return <Loader />;

    if (markerLogs?.length === 0) {
      return (
        <Row justify="center">
          <Title>No Marker Logs</Title>
        </Row>
      );
    }

    return (
      <>
        <Title>Marker Logs Details</Title>
        <MarkerLogsContainer>
          {markerLogs?.map((log) => {
            return <MarkerLogDetails markerLog={log} key={log.key} />;
          })}
        </MarkerLogsContainer>

        <Button
          loading={loading}
          text="More Logs"
          handleButtonClick={handleMoreButton}
        />
      </>
    );
  }

  return (
    <LogContainer>
      <Button
        handleButtonClick={() => history.goBack()}
        text={<ArrowLeft />}
        anchor="left"
      />
      {renderMarkerLogs()}
    </LogContainer>
  );
};

export default MarkersLogsDetails;
