import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import color from '../../utils/color';

export default styled(Typography).attrs({ component: 'span' })`
  && {
    color: ${props => props.color || color.black};
    font-size: ${props => props.size || '14px'};
    font-weight: ${props => props.weight || 'normal'};
  }
`;
