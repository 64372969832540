import React, { useContext } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Properties from "./Properties";
import PropertyMarkers from "./PropertiesMarker";
import Loader from "../../Generics/snowm_loader";
import { ServiceTypesContext } from "../../../contexts/service_types";

export default function () {
  const services = useContext(ServiceTypesContext);
  const subscribedServices = services?.subscribedServices;

  if (!subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices.properties) {
    return <Redirect to="/home" />;
  }

  return (
    <Switch>
      <Route path="/home/properties/:key" component={PropertyMarkers} />
      <Route path="/home/properties" component={Properties} />
    </Switch>
  );
}
