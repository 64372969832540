import React, { useState, useContext } from 'react';
import { Typography, Button } from '@material-ui/core';

import color from '../../../../utils/color';
import ADialog from '../../../Common/styled';
import { Col, Row } from '../../../../styles/snowm_styled';
import PrimaryButton from '../../../Common/primary_button';
import { LocalizationContext } from '../../../../contexts/localization_context';

const LanguageSettingModal = ({ open, onClose, onConfirm }) => {
  const [language, setLanguage] = useState('');

  const languageOptions = [
    {
      value: 'en',
      label: 'English'
    },
    {
      value: 'hi',
      label: 'Hindi'
    },
    {
      value: 'fr',
      label: 'French'
    }
  ];

  const handleClose = () => {
    onClose();
  };

  const handleConfirmButton = () => {
    onConfirm(language);
    localStorage.setItem('locale', language);
    window.location.reload();
  };

  const handleChange = event => {
    event.preventDefault();

    setLanguage(event.target.value);
  };

  const locale = localStorage.getItem('locale') || 'en';

  const { strings } = useContext(LocalizationContext);
  const {
    misc: { CHANGE_LANGUAGE },
    action: { CANCEL, CONFIRM }
  } = strings;

  return (
    <ADialog
      height="20%"
      justifycontent="space-around"
      width="50%"
      padding="1em 1em 20px 1em"
      open={open}
      onClose={handleClose}
    >
      <Col>
        <Typography style={{ fontWeight: 700 }}>{CHANGE_LANGUAGE}</Typography>

        <div style={{ marginTop: '1em' }}>
          <select
            onChange={handleChange}
            defaultValue={locale}
            name="languages"
            style={{ width: '100%', padding: 5, backgroundColor: 'white' }}
          >
            {Object.values(languageOptions).map(languageOption => (
              <option key={languageOption.value} value={languageOption.value}>
                {languageOption.label}
              </option>
            ))}
          </select>
        </div>
      </Col>
      <Row justify="flex-end">
        <Button
          onClick={() => handleClose()}
          style={{ color: color.snomwBlue, fontSize: 14, marginRight: 10 }}
        >
          {CANCEL}
        </Button>
        <PrimaryButton onClick={() => handleConfirmButton()}>
          {CONFIRM}
        </PrimaryButton>
      </Row>
    </ADialog>
  );
};

export default LanguageSettingModal;
