import React from 'react';
import { RoutesProvider } from './service_routes';
import { ServicePProvider } from './service_provider';
import { JobsProvider } from './service_jobs';
import { BeaconMsProvider } from './beaconMs';
import { ServicePointsProvider } from './service_points';
import { ServiceProvider } from './services';
import { AuthProvider } from './auth_context';
import { ServiceTypeProvider } from './service_types';
import { LocalizationProvider } from './localization_context';

export default function ContextProvider({ children }) {
  return (
    <LocalizationProvider>
      <AuthProvider>
        <ServicePProvider>
          <RoutesProvider>
            <ServicePointsProvider>
              <JobsProvider>
                <ServiceProvider>
                  <BeaconMsProvider>
                    <ServiceTypeProvider>{children}</ServiceTypeProvider>
                  </BeaconMsProvider>
                </ServiceProvider>
              </JobsProvider>
            </ServicePointsProvider>
          </RoutesProvider>
        </ServicePProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
}
