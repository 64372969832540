import React, { useReducer, useState } from 'react';

import {
  TextField,
  CircularProgress,
  withTheme,
  Button,
} from '@material-ui/core';

import Span from '../Common/Span';
import color from '../../utils/color';
import { Col, Row } from '../../styles/snowm_styled';
import { updatePassword } from '../../controllers/snowm_firebase';
import UnAuthenticated, {
  StyledOutlineButton,
  passwordRange,
} from './UnAuthenticated';

const ChangePassword = ({ fromProfile, handleSnackbar, onClose, ...props }) => {
  const initialState = {
    newPassword: '',
    checkPassword: '',
  };

  function reducer(stateData, action) {
    switch (action.type) {
      case 'newPassword':
        return {
          ...stateData,
          newPassword: action.value,
        };

      case 'checkPassword':
        return {
          ...stateData,
          checkPassword: action.value,
        };
      case 'oldPassword':
        return {
          ...stateData,
          oldPassword: action.value,
        };
      default:
        throw new Error();
    }
  }

  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwords, dispatch] = useReducer(reducer, initialState);
  const [samePasswordError, setSamePasswordError] = useState(false);

  function handlePasswordChange(event, type) {
    event.stopPropagation();
    setSamePasswordError(false);
    dispatch({
      type,
      value: event.target.value,
    });
  }

  function showErrorMessage(error) {
    switch (error.code) {
      case 'auth/wrong-password':
        alert('The old password is not correct.');
        break;

      case 'auth/too-many-requests':
        alert('Too many requests. Try again later.');
        break;

      default:
        alert('Cannot update the password');
        break;
    }
  }

  function notMatchPassword() {
    return (
      passwords.checkPassword !== '' &&
      passwords.newPassword !== passwords.checkPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    if (notMatchPassword()) {
      setErrMsg("Passwords don't match.");
      setLoading(false);
      return;
    }
    try {
      await updatePassword(
        passwords.newPassword,
        passwords.oldPassword,
        fromProfile
      );
      if (handleSnackbar) {
        handleSnackbar();
      }
      setLoading(false);
    } catch (error) {
      showErrorMessage(error);
      console.error('@@error', error);
      setLoading(false);
    }
  }

  return (
    <UnAuthenticated fromProfile={fromProfile}>
      <form onSubmit={handleSubmit}>
        <Row justify="center">
          <Span weight="bold">Change Password</Span>
        </Row>
        <Col justify="center">
          <Row width="auto">
            <TextField
              label="Current Password"
              autoComplete="current-password"
              onChange={(e) => handlePasswordChange(e, 'oldPassword')}
              required
              type="password"
            />
          </Row>
          <Row width="auto">
            <TextField
              label="New Password"
              autoComplete="new-password"
              onChange={(e) => handlePasswordChange(e, 'newPassword')}
              required
              type="password"
              inputProps={passwordRange}
            />
          </Row>
          <Row>
            <TextField
              label="Confirm New Password"
              autoComplete="new-password"
              onChange={(e) => handlePasswordChange(e, 'checkPassword')}
              required
              type="password"
              error={notMatchPassword()}
              inputProps={passwordRange}
              helperText={errMsg}
            />
          </Row>
          <Row>
            {fromProfile && (
              <Button color="primary" onClick={onClose}>
                Cancel
              </Button>
            )}
            <StyledOutlineButton
              type="submit"
              variant="contained"
              disabled={loading}
              color="primary"
              width={fromProfile && 'auto'}
              flexbasis={!fromProfile && '300px'}
              {...props}
            >
              {loading ? (
                <CircularProgress size={15} />
              ) : (
                <Span color={color.white}>Change Password</Span>
              )}
            </StyledOutlineButton>
          </Row>
          {samePasswordError && (
            <Row>
              <Span color="error">
                Old Password and new password can't be the same.
              </Span>
            </Row>
          )}
        </Col>
      </form>
    </UnAuthenticated>
  );
};

export default withTheme(ChangePassword);
