/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: index.jsx
 *
 *  Description: Defines Routes for Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 9-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useContext } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Loader from "../../Generics/snowm_loader";
import ServicePoints from "./snowm_service_points";
import MarkersLogsDetails from "./LogDetailOfMarker";
import { ServiceTypesContext } from "../../../contexts/service_types";

export default function () {
  const data = useContext(ServiceTypesContext);

  const { subscribedServices } = data;

  if (!subscribedServices) return <Loader />;

  const { markers } = subscribedServices;

  if (!markers) return <Redirect to="/home" />;

  return (
    <Switch>
      <Route path="/home/markers/:key" component={MarkersLogsDetails} />
      <Route path="/home/markers/" component={ServicePoints} />
      <Redirect to="/home/markers" />
    </Switch>
  );
}
