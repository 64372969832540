import styled from "styled-components";
import GoogleMap from "google-map-react";
import React, { useState, useEffect } from "react";

import Marker from "./snowm_marker";
import Polyline from "./snowm_polyline";
import { decodePolylineToLatLng } from "../../../helpers/polyline";

function Map({
  single,
  center,
  zoom,
  points,
  polyline = "",
  onMarkerTap,
  edit,
  selectedMarker,
  dashboard,
}) {
  const [mapInfo, setMapInfo] = useState({
    loaded: false,
    map: null,
    maps: null,
  });

  const [newCenter, setNewCenter] = useState([]);

  const [listOfPoints, setListOfPoints] = useState([]);

  // ever the polyline(string) changes in route automatically the polyline coordinates are populated
  useEffect(() => {
    if (polyline) {
      const tempList = decodePolylineToLatLng(polyline);
      setListOfPoints([...tempList]);
    } else {
      setListOfPoints();
    }
  }, [polyline]);

  useEffect(() => {
    if (selectedMarker) {
      setNewCenter([selectedMarker.latitude, selectedMarker.longitude]);
    } else {
      setNewCenter(center);
    }
  }, [selectedMarker]);

  function onMapLoaded(m, ms) {
    setMapInfo({
      loaded: true,
      map: m,
      maps: ms,
    });
  }

  const onTap = (point) => {
    if (onMarkerTap) {
      onMarkerTap(point);
    }
  };

  function renderMarkers(mapPoints) {
    if (mapPoints) {
      const ps = Object.values(mapPoints);
      if (ps.length !== 0) {
        return ps.map((point) => {
          const key = point.key ?? point.uid;
          if (
            (point && point.serviceType === "outdoor") ||
            (point && point.indoor === false) ||
            point.latitude
          ) {
            if (mapInfo.map) {
              mapInfo.map.setCenter({
                lat: point.latitude,
                lng: point.longitude,
              });
            }

            return (
              <Marker
                point={point}
                key={point.key || point.uid}
                assigned={point.assigned}
                lat={point.latitude}
                lng={point.longitude}
                onTap={() => onTap(point)}
                edit={edit}
                show={selectedMarker?.key === key}
                dashboard={dashboard}
              />
            );
          }
          if (single) {
            return <NoInfo key={point.key}>No Location Information</NoInfo>;
          }
          return <React.Fragment key={point.key}></React.Fragment>;
        });
      }
    }
    return <></>;
  }

  function afterMapLoadChanges() {
    return (
      <>
        <Polyline
          map={mapInfo.map}
          maps={mapInfo.maps}
          markers={listOfPoints || []}
        />
      </>
    );
  }

  return (
    <GoogleMap
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
      center={newCenter}
      defaultZoom={zoom}
      onGoogleApiLoaded={({ map: m, maps: ms }) => onMapLoaded(m, ms)}
      distanceToMouse={() => {}}
    >
      {renderMarkers(points)}
      {mapInfo.loaded && afterMapLoadChanges()}
    </GoogleMap>
  );
}

Map.defaultProps = {
  center: [47.367347, 8.5500025],
  zoom: 13,
};

export default React.memo(Map);

const NoInfo = styled.div`
  color: #d8000c;
  font-style: italic;
  font-size: 16px;
`;
