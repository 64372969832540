import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Card, CircularProgress, Divider, Tooltip } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { Row } from '../../../styles/snowm_styled';
import ListTitleItem from '../../Generics/list_title_item';
import {
  getServicePointById,
  getJobInfo,
  getProviderByUid,
} from '../../../controllers/snowm_firebase';

const RequestItem = ({
  request,
  onDetailPage,
  handleDelete,
  deleting,
  handleEdit,
}) => {
  const [marker, setMarker] = useState();
  const [job, setJob] = useState(null);
  const [crews, setCrews] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (request.markerKey) {
      getServicePointById(request.markerKey).then((res) => {
        setMarker(res);
      });
    }

    if (request.status !== 'REQUESTED' && request.jobKey) {
      getJobInfo(request.jobKey).then((res) => {
        setJob(res);
      });
    }
  }, [request]);

  useEffect(() => {
    const crewPromises = job?.providerUids?.map((crewId) => {
      return getProviderByUid(crewId);
    });

    Promise.all(crewPromises ?? []).then((res) => {
      setCrews(res);
    });
  }, [job]);

  function handleWorkOrderClick(e) {
    e.stopPropagation();
    if (!onDetailPage) {
      history.push(
        `/home/serviceRequests/marker/${request.markerKey}/order/${request.id}`
      );
    }
  }

  function getColor(status) {
    switch (status) {
      case 'REQUESTED':
        return color.snomwBlue;
      case 'DISPATCHED':
        return color.amber;
      case 'COMPLETED':
        return color.green;
      default:
        return color.gradientEndColor;
    }
  }

  function getNameFromCrews() {
    const crewsName = crews?.map((crew) => crew.name);
    return crewsName?.join(' ,');
  }

  function handleDeleteIconClick(event) {
    event.stopPropagation();
    handleDelete();
  }

  function handleEditIconClick(event) {
    event.stopPropagation();
    handleEdit();
  }

  function getJobDetails() {
    if (request.status === 'REQUESTED' && !onDetailPage) {
      return (
        <>
          <Divider />
          <Action justify="space-around">
            {deleting ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <DeleteIcon
                  fontSize="small"
                  color="error"
                  onClick={handleDeleteIconClick}
                />
                <EditIcon fontSize="small" onClick={handleEditIconClick} />
              </>
            )}
          </Action>
        </>
      );
    }

    if (request.status === 'DISPATCHED') {
      return (
        <>
          <Divider />
          <ListTitleItem title="Assigned To" content={getNameFromCrews()} />
        </>
      );
    }
    return null;
  }

  return (
    <StyledCard onClick={handleWorkOrderClick}>
      <Row justify="space-between">
        <Span weight="bold">{marker?.name ?? 'Marker Deleted'}</Span>
        <StatusContainer
          bgcolor={getColor(request.status)}
          width="auto"
          marginbottom="0"
        >
          <Span color={color.white} size="12px">
            {request.status}
          </Span>
        </StatusContainer>
      </Row>
      <ListTitleItem
        title="Expected Service Time"
        content={request.preferredTime}
      />
      <ListTitleItem title="Urgency" content={request.urgency} />
      <Row>
        {!onDetailPage ? (
          <Tooltip title={request.requestNote}>
            <LongText>{request.requestNote}</LongText>
          </Tooltip>
        ) : (
          <BrokenText>{request.requestNote}</BrokenText>
        )}
      </Row>
      {getJobDetails()}
    </StyledCard>
  );
};

export default RequestItem;

const StyledCard = styled(Card)`
  padding: 12px;
  cursor: pointer;
`;

const StatusContainer = styled(Row)`
  background-color: ${(props) => props.bgcolor};
  padding: 2px 4px;
  border-radius: 4px;
`;

const LongText = styled(Span)`
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Action = styled(Row)`
  margin-top: 8px;
`;

const BrokenText = styled(Span)`
  word-break: break-word;
`;
