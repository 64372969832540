/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_service_points.jsx
 *
 *  Description: Show the list of service points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useContext } from "react";

import { isEmpty } from "lodash";
import styled from "styled-components";
import { Redirect } from "react-router";

import { StyledGrid } from "../styled";
import Loader from "../../Generics/snowm_loader";
import { Row } from "../../../styles/snowm_styled";
import ServicePointItem from "./service_point_item";
import { ServiceTypesContext } from "../../../contexts/service_types";
import { ServicePointsContext } from "../../../contexts/service_points";

export default function (servicePointProps) {
  return (
    <ServicePointsContext.Consumer>
      {({ markers, fetchServicePoints }) => {
        return (
          <ServicePoints
            markers={markers}
            fetchServicePoints={fetchServicePoints}
            {...servicePointProps}
          />
        );
      }}
    </ServicePointsContext.Consumer>
  );
}

function ServicePoints() {
  const data = useContext(ServiceTypesContext);

  const { markers } = data;

  if (!markers) {
    return <Loader />;
  }

  if (markers?.length === 0) {
    return <Redirect to="/home" />;
  }

  if (markers) {
    return (
      <StyledContainer>
        <StyledGrid>
          {markers?.map((marker) => {
            return <ServicePointItem key={marker.key} marker={marker} />;
          })}
        </StyledGrid>

        {isEmpty(markers) && <Row justify="center">No Markers</Row>}
      </StyledContainer>
    );
  }
  return <Loader />;
}

const StyledContainer = styled.div`
  padding: 16px;
  width: 100%;
`;
