import React from 'react';

import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { Progress } from '../Generics/snowm_loader';

const PrimaryButton = ({ loading, onClick, children, disabled, ...rest }) => (
  <PButton
    disabled={loading || disabled}
    onClick={onClick}
    variant="contained"
    color="primary"
    autoFocus
    {...rest}
  >
    {loading ? <Progress /> : <>{children}</>}
  </PButton>
);

const PButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 6px 16px;
    border-radius: 8px;
    font-weight: 900;
    background-image: linear-gradient(to right, #00afee, #006eb9);
  }
`;

export default PrimaryButton;
