import React, { useContext } from 'react';

import { Switch, Route, Redirect } from 'react-router';

import ActiveRoutes from './ActiveRoutes';
import RouteDetails from './RouteDetails';
import LogDetailOfAMarker from './LogDetailOfMarker';
import { ServiceTypesContext } from '../../../contexts/service_types';

export default function () {
  const data = useContext(ServiceTypesContext);

  const subscribedServices = data?.subscribedServices;

  if (!subscribedServices?.isRouteAvailable) {
    return <Redirect to="/home" />;
  }

  return (
    <Switch>
      <Route
        path="/home/routes/:jobKey/:key/marker/:markerKey"
        component={LogDetailOfAMarker}
      />
      <Route path="/home/routes/:jobKey/:key" component={RouteDetails} />
      <Route path="/home/routes" component={ActiveRoutes} />
    </Switch>
  );
}
