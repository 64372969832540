/* eslint-disable no-bitwise */
import firebase from 'firebase';
import 'firebase/functions';

export function decodePolylineToLatLng(encoded) {
  if (encoded == null) {
    return [];
  }

  const poly = [];
  let index = 0;
  const len = encoded.length;
  let lat = 0;
  let lng = 0;

  while (index < len) {
    let b;
    let shift = 0;
    let result = 0;

    do {
      b = encoded.charCodeAt(index) - 63;
      index += 1;

      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;

    do {
      b = encoded.charCodeAt(index) - 63;
      index += 1;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    poly.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return poly;
}

function latLngToString(point) {
  return `${point.latitude},${point.longitude}`;
}

// yet to be tested
export async function getRouteForPoints(points) {
  if (!points[0]) return null;
  if (points.length < 2) return null;
  const first = latLngToString(points[0]);
  const last = latLngToString(points[points.length - 1]);
  let url = `https://maps.googleapis.com/maps/api/directions/json?origin=${first}&destination=${last}`;

  if (points.length > 2) {
    url += '&waypoints=';
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < points.length - 1; i++) {
      const str = latLngToString(points[i]);
      url += `${str}|`;
    }
  }

  url += `&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

  const { data } = await firebase
    .functions()
    .httpsCallable('networkCallProxy-networkCallProxy')({
    url,
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  });

  let distance = 0;
  let duration = 0;
  Object.values(data.data.routes[0].legs).forEach((item) => {
    distance += item.distance.value;
    duration += item.duration.value;
  });

  return {
    routePath: data.data.routes[0].overview_polyline.points,
    distance,
    duration,
  };
}
