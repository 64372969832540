import React, { createContext, useState, useEffect, useContext } from 'react';

import { AuthContext } from './auth_context';
import {
  getPropertiesFromFirestore,
  getMarkerAssignedToCustomer,
  getPropertyAssignedMarkers,
  getServiceInfo,
} from '../controllers/snowm_firebase';

export const ServiceTypesContext = createContext();

export function ServiceTypeProvider({ children }) {
  const [subscribedServices, setSubscribedServices] = useState();
  const [properties, setProperties] = useState();
  const [markers, setMarkers] = useState();
  const [propertyTypeMarkers, setPropertyTypeMarkers] = useState();
  const [customerMarkers, setCustomerMarkers] = useState();

  const authValues = useContext(AuthContext);

  function _updateProperties(res) {
    setProperties(res);
  }

  function updateMarkers(res) {
    setCustomerMarkers(res);
  }

  useEffect(() => {
    const propertiesKeys = properties?.map((property) => property?.id) ?? [];

    if (authValues?.user && properties) {
      getMarkerAssignedToCustomer(authValues?.user, updateMarkers);
      getPropertyAssignedMarkers(propertiesKeys).then((res) => {
        setPropertyTypeMarkers(res);
      });
    }
  }, [properties, authValues]);

  useEffect(() => {
    if (propertyTypeMarkers && customerMarkers) {
      setMarkers([...(propertyTypeMarkers ?? []), ...(customerMarkers ?? [])]);
    }
  }, [propertyTypeMarkers, customerMarkers]);

  async function checkIfRoutesTypeService() {
    const servicesKeys = markers?.map((marker) => marker.serviceKey) ?? [];

    const uniqueServiceKeys = [...new Set(servicesKeys)];

    const servicesPromise = uniqueServiceKeys?.map((serviceKey) => {
      return getServiceInfo(serviceKey);
    });

    const services = await Promise.all(servicesPromise);

    const outdoorBasedService = services?.find(
      (service) => service.type === 'outdoor'
    );

    return !!outdoorBasedService;
  }

  useEffect(() => {
    let features = {
      properties: properties?.length > 0,
      markers: markers?.length > 0,
    };

    checkIfRoutesTypeService().then((res) => {
      features = { ...features, isRouteAvailable: res };
      if (properties && markers) {
        setSubscribedServices(features);
      }
    });
  }, [properties, markers]);

  const getDetails = async () => {
    getPropertiesFromFirestore(authValues?.user, _updateProperties);
  };

  return (
    <ServiceTypesContext.Provider
      value={{
        subscribedServices,
        getDetails,
        properties,
        markers,
      }}
    >
      {children}
    </ServiceTypesContext.Provider>
  );
}
