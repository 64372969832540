import React from 'react';

import { Route, Switch } from 'react-router';

import JobDetail from './JobDetail';
import LogDetail from './LogDetail';

export default function () {
  return (
    <Switch>
      <Route
        exact
        path="/home/servicelogs/details/:jobId/:markerLogKey"
        component={JobDetail}
      />
      <Route path="/home/servicelogs" component={LogDetail} />
    </Switch>
  );
}
