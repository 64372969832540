import styled from "styled-components";
import { Card, Typography, TextField } from "@material-ui/core";

import { Row } from "../../styles/snowm_styled";

export const OptionsOverlay = styled.div`
  z-index: 10;
  position: absolute;
  right: 0px;
  top: 0px;
  visibility: hidden;
  display: flex;
  flex-direction: row;
`;

export const OverlayCard = styled(Card)`
  &:hover ${OptionsOverlay} {
    visibility: visible;
  }
  && {
    margin: 10px;
    display: flex;
    position: relative;
    flex-direction: row;
  }
`;

export const StyledDiv = styled.div``;

export const Icon = styled.div`
  & > svg {
    cursor: pointer;
    font-size: 12px;
    padding: 5px;
  }
`;

export const FloatingButton = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 20px;
  z-index: 10;
`;

export const ServiceType = styled(Typography)`
  margin-left: ${(props) => props.marginleft || "0"};
  color: white;
  text-transform: uppercase;
  padding: ${(props) => props.padding || "8px 24px"};
  border-radius: 10px;
  background-color: #173964;
`;

export const SpanKey = styled(Typography)`
  color: grey;
  font-weight: 100;
`;

export const SpanValue = styled(Typography)`
  && {
    color: #173964;
    margin-left: 10px;
    text-align: right;
  }
`;
export const DetailsRow = styled(Row)`
  && {
    margin-top: 10px;
    justify-content: space-between;
  }
`;

export const StyledTextField = styled(TextField)`
  flex-grow: 0.75;
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiInputBase-input::placeholder {
    opacity: 0.65;
    font-size: 18px;
  }
  .MuiOutlinedInput-input {
    padding: 14px;
  }
`;

export const StyledGrid = styled.div`
  padding: ${(props) => props.padding ?? "12px"};
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;
