import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import ClockFastIcon from 'mdi-react/ClockFastIcon';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import { Card, CardContent, Typography } from '@material-ui/core';

import { BoldTitle } from './styled';
import Loader from '../../Generics/snowm_loader';
import { Row } from '../../../styles/snowm_styled';
import ProgressCircle, { getProgress } from './ProgressCircle';
import { getRouteDetailsOfAJob } from '../../../controllers/snowm_firebase';
import { LocalizationContext } from '../../../contexts/localization_context';

const RouteDetailOfAJob = ({ jobKey, routeKey, isPeriodic }) => {
  const history = useHistory();

  const [route, setRoute] = useState({});
  const [numberOfMarkers, setNumberOfMarkers] = useState({
    totalNumberOfMarkers: 0,
    numberOfServedMarkers: 0,
  });

  const { strings } = useContext(LocalizationContext);

  const { MARKERS } = strings?.sidemenu;
  const { SERVED_MARKERS } = strings?.markers;
  const { DISTANCE } = strings?.misc;

  useEffect(() => {
    let isSubscribe = true;
    const callback = (routeDetails) => {
      if (isSubscribe) setRoute(routeDetails);
    };

    getRouteDetailsOfAJob(jobKey, routeKey, isPeriodic, callback);

    return () => {
      isSubscribe = false;
    };
  }, [jobKey, routeKey, isPeriodic]);

  useEffect(() => {
    setNumberOfMarkers({
      totalNumberOfMarkers: route.servicePointsKeys?.length ?? 0,
      numberOfServedMarkers: route?.servedPointsKeys?.length ?? 0,
    });
  }, [route]);

  const handleRoute = () => {
    if (route.serviceType === 'indoor') {
      history.push(`/home/jobs/details/${jobKey}/indoor/route/${routeKey}`);
    } else if (route.serviceType === 'outdoor') {
      history.push(`/home/jobs/details/${jobKey}/outdoor/route/${routeKey}`);
    }
  };

  if (Object.values(route).length === 0) return <Loader />;

  return (
    <Row>
      <Card
        onClick={handleRoute}
        style={{ cursor: 'pointer', margin: '10px 0' }}
      >
        <CardContent>
          <Row justify="space-between">
            <div>
              <BoldTitle variant="h6">{route.name}</BoldTitle>
              <Row>
                <HomeMapMarkerIcon />
                <StyledRow justify="space-between">
                  <StyledRouteDetailTitle>{MARKERS}:</StyledRouteDetailTitle>
                  <BoldTitle>{numberOfMarkers.totalNumberOfMarkers}</BoldTitle>
                </StyledRow>
              </Row>
              <Row>
                <HomeMapMarkerIcon />
                <StyledRow justify="space-between">
                  <StyledRouteDetailTitle>
                    {SERVED_MARKERS}:
                  </StyledRouteDetailTitle>
                  <BoldTitle>{numberOfMarkers.numberOfServedMarkers}</BoldTitle>
                </StyledRow>
              </Row>
              {route.serviceType === 'outdoor' && (
                <Row>
                  <ClockFastIcon />
                  <StyledRow justify="space-between">
                    <StyledRouteDetailTitle>{DISTANCE}:</StyledRouteDetailTitle>
                    <BoldTitle>{`${route.distance} meters`}</BoldTitle>
                  </StyledRow>
                </Row>
              )}
            </div>

            <ProgressCircle
              progress={getProgress(
                (numberOfMarkers.numberOfServedMarkers /
                  numberOfMarkers.totalNumberOfMarkers) *
                  100
              )}
            />
          </Row>
        </CardContent>
      </Card>
    </Row>
  );
};

export default RouteDetailOfAJob;

const StyledRow = styled(Row)`
  margin-top: 5px;
`;

const StyledRouteDetailTitle = styled(Typography)`
  && {
    margin-left: 5px;
  }
`;
