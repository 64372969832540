/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: service_jobs.js
 *
 *  Description: Context API for Jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { createContext, useState } from 'react';
import {
  getRealTimeJobs,
  getJobInfo,
  getRealTimePeriodicJobs
} from '../controllers/snowm_firebase';

export const JobsContext = createContext();

export function JobsProvider({ children }) {
  const [jobs, setJobs] = useState(null);

  function _updateJobs(jobsData) {
    setJobs(jobsData);
  }

  function fetchJobs() {
    getRealTimeJobs(_updateJobs);
  }

  function fetchPeriodicJobs() {
    getRealTimePeriodicJobs(_updateJobs);
  }

  async function getJobDetail(jobId) {
    if (jobs === null) {
      const jobDetail = await getJobInfo(jobId);
      return jobDetail;
    }
    return jobs[jobId];
  }

  return (
    <JobsContext.Provider
      value={{
        jobs,
        fetchJobs,
        fetchPeriodicJobs,
        getJobDetail
      }}
    >
      {children}
    </JobsContext.Provider>
  );
}
