import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

export default function ProgressCircle({ progress, margin }) {
  const progressBarRef = React.useRef();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function update() {
      const circle = progressBarRef.current;
      const radius = circle.getAttribute('r');
      const circum = Math.PI * (radius * 2);
      const pct = ((100 - progress) / 100) * circum;
      setOffset(pct);
    }

    update();
  }, [progress]);

  return (
    <Progress progress={progress} margin={margin}>
      <svg
        id="svg"
        width="200"
        height="200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <GreyCircle r={60} cx="100" cy="100" fill="transparent" />
        <BlueCircle
          ref={progressBarRef}
          offset={offset}
          r={60}
          cx="100"
          cy="100"
          fill="transparent"
        />
      </svg>
    </Progress>
  );
}

export const getProgress = prog => {
  if (Number.isNaN(prog)) return 0;
  if (prog > 100) return 100;
  if (prog < 0) return 0;
  return prog.toFixed(2);
};

const GreyCircle = styled.circle`
  stroke: #f1f1f1;
  stroke-width: 1em;
`;

const BlueCircle = styled.circle`
  stroke-dashoffset: ${props => props.offset};
  stroke-dasharray: ${props => props.r * Math.PI * 2};
  transition: stroke-dashoffset 1s linear;
  stroke-width: 1em;
  stroke: #29abe2;
  transform-origin: 100px 100px;
  transform: rotate(-90deg);
`;

const Progress = styled.div`
display: block;
  height: 200px;
  width: 200px;
  margin: ${props => props.margin || '-1.5em auto -1em'}
  border-radius: 100%;
  position: relative;

  &:after {
    position: absolute;
    display: block;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    content: '${props => props.progress}%';
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    font-size: 1.5em;
    color:#29ABE2;
    text-align: center;
  }
`;
