import React, { createContext, useState } from 'react';
import { getRealTimeServices } from '../controllers/snowm_firebase';

export const ServiceContext = createContext();

export function ServiceProvider({ children }) {
  const [services, setServices] = useState(null);

  function _updateServices(serviceData) {
    setServices(serviceData);
  }

  function fetchServices() {
    getRealTimeServices(_updateServices);
  }
  return (
    <ServiceContext.Provider
      value={{
        fetchServices,
        services
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}
